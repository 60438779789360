import {useState} from 'react';
import {GAMES_TO_SHOW_INITIALLY} from "../../../lib/utils/constants/GlobalConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styles from "./FooterLinksSectionV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const FooterLinksSectionComponentV2 = (props: any) => {
    const {footerData} = props;
    const [readMore, setReadMore] = useState(false);
    const showMore = () => {
        setReadMore(prev => !prev);
    }

    const linkComponentUI = (index: number, item: any) => {
        return (
            <li key={"linkComponent_UI_" + index}>
                <CustomLinkComponent href={item.path}>
                    {item.text}
                </CustomLinkComponent>
            </li>
        );
    }

    const anchorComponentUI = (index: number, item: any) => {
        return (
            <li key={"anchorComponent_UI_" + index}>
                <a href={item.path}>
                    {item.text}
                </a>
            </li>
        );
    }

    return (
        footerData && footerData.gamesSections ?
            <div className={multipleClassName("container-fluid", styles.footerLinksSectionV2)}>
                {footerData.blogHeading && <>
                    <div className={"row"}>
                        <div className={multipleClassName("col-4", styles.lineParentV2)}>
                            <div className={styles.leftLineV2}></div>
                        </div>
                        <div className={multipleClassName("col-4", styles.headingFooterLinkV2)}
                             style={{fontSize: footerData.blogHeading.length > 12 ? 10 : 15}}>
                            {footerData.blogHeading}
                        </div>
                        <div className={multipleClassName("col-4", styles.lineParentV2)}>
                            <div className={styles.rightLineV2}></div>
                        </div>
                    </div>
                    {footerData.blogSections && <div className={"row"}>
                        <div className={"col-12"}>
                            <ul className={styles.footerUListV2}>{
                                footerData.blogSections.map((item, index) => {
                                    return anchorComponentUI(index, item)
                                })
                            }
                            </ul>
                        </div>
                    </div>}
                </>
                }
                <div className={"row"}>
                    <div className={multipleClassName("col-4", styles.lineParentV2)}>
                        <div className={styles.leftLineV2}></div>
                    </div>
                    <div className={multipleClassName("col-4", styles.headingFooterLinkV2)}
                         style={{fontSize: footerData.blogHeading.length > 12 ? 10 : 15}}>
                        {footerData.gamesHeading}
                    </div>
                    <div className={multipleClassName("col-4", styles.lineParentV2)}>
                        <div className={styles.rightLineV2}></div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className={styles.footerUListV2}>
                            {
                                footerData.gamesSections.map((item, index) => {
                                    return (
                                        !readMore
                                            ?
                                            index < GAMES_TO_SHOW_INITIALLY ? linkComponentUI(index, item) : null
                                            :
                                            linkComponentUI(index, item)
                                    )
                                })

                            }
                        </ul>
                    </div>
                </div>
                <div className={styles.readMoreDivV2} onClick={() => showMore()}>{!readMore ?
                    <div style={{marginRight: '5px'}}>
                        {footerData.readMoreText}
                    </div>
                    :
                    <div style={{marginRight: '5px'}}>
                        {footerData.readLessText}
                    </div>}
                    <div className={styles.readMoreImageV2}
                         style={{transform: `${!readMore ? "rotate(180deg)" : "rotate(0deg)"}`}}>
                        <CustomImageComponent
                            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/showMoreIconV2.svg"
                            layout={"fill"}
                            objectFit={"contain"}
                            alt={"about-us-image"}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={multipleClassName("col-4", styles.lineParentV2)}>
                        <div className={styles.leftLineV2}></div>
                    </div>
                    <div className={multipleClassName("col-4", styles.headingFooterLinkV2)}
                         style={{fontSize: footerData.policiesHeading.length > 12 ? 10 : 15}}>
                        {footerData.policiesHeading}
                    </div>
                    <div className={multipleClassName("col-4", styles.lineParentV2)}>
                        <div className={styles.rightLineV2}></div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className={styles.footerUListV2}>
                            {
                                footerData.policiesSections.map((item, index) => {
                                    return (
                                        !readMore
                                            ?
                                            index < GAMES_TO_SHOW_INITIALLY ? linkComponentUI(index, item) : null
                                            :
                                            linkComponentUI(index, item)
                                    )
                                })

                            }
                        </ul>
                    </div>
                </div>
            </div> : <></>
    )

}

export default FooterLinksSectionComponentV2;